import React from 'react'
import './legalFooter.css'
import legalFooter from '../utils/Texts/legalFooter.json'
import { useLangContext } from '../../Context/LangContext'
import { isMobileOnly } from 'react-device-detect'
const {REACT_APP_BE}= process.env

const LegalFooter = () => {
  const {selectedLanguage} = useLangContext()
  const text = legalFooter[selectedLanguage];
  return (

    <div className='legalFooterBox'>
        <div className={isMobileOnly? "mobile-wrapper":"wrapper"}>
          <div className={isMobileOnly ? "data-logo-mobile":'dataFiscalLogo'}>
          <img src="/img/datafiscal.png" alt="Logo Data Fiscal"className='fiscalLogo'/>
          </div>
          <div className='legalText'>
              <p>{text.firstCol}</p>
        </div>
        <div className={isMobileOnly? "ssn-logo-mobile":'ssnLogoBox'}>
          <img src="/img/ssn.png" alt="Logo Superintendencia de Seguros de la Nación"className='ssnLogo'/>
          </div>
          <div className='ssnText'>
              <p >{text.secondCol}</p>
          </div>
          <div className='service'>
              <p className='service-text'>{text.service.firstText}</p>
              <p className='service-sub'>{text.service.subtitle}</p>
             <div className='service-responsables'>
             <div className='service-person-box'>
             <h5>{text.service.responsible}</h5>
             <p className='service-person'>Agustín Del Torchio</p>
             <p className='service-text'> Tel. 011 3986 2800 | Int. 3008 </p>
             <p className='service-text'> email: adeltorchio@afianzadora.com.ar</p>
             </div>
             <div>
             <h5>{text.service.substitute}</h5>
             <p className='service-person'>Vanesa Pascale</p>
             <p className='service-text'>Tel. 011 3986 2800 | Int. 5020</p>
             <p className='service-text'>email: vpascale@afianzadora.com.ar</p>
             </div>
             </div>
             <div className='service-responsables'>
             <p className='service-text'>{text.service.lastText}</p>
             </div>
          </div>
        </div>
        <div className={isMobileOnly? "display-none":'circle-box'}>
          <a href={REACT_APP_BE} target="_blank" rel="noreferrer">
            <img src='/img/HexaW.png' alt="" className='circle-logo' />
          </a>
        </div>
       <p className={isMobileOnly? "mobile-sign":'sign'}>andycambra@gmail.com | design | dev</p>
    </div>
 
  )
}

export default LegalFooter