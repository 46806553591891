import React, {useState} from 'react'
import { isMobile } from 'react-device-detect'
const {REACT_APP_ENDPOINT}=process.env

const CotizadorAlquilerCliente = ({handleChange, handleSubmitThanks, rentUser, handleCancel, errors, clientData, placeholders, rentResponse, errorNetworkMessage}) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [newScopeContract, setNewScopeContract] = useState('');
  const [newScope, setNewScope] = useState('');
  const [notification, setNotification] = useState(null)
  const [notificationContract, setNotificationContract] = useState(null)

  const uploadFile = async (type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    fetch(`${REACT_APP_ENDPOINT}public-api/upload-file`, {
      method: 'POST',
      body: formData,
    }).then((response) => {
      response.json().then((body) => {
        if(type === 'contract') {
          rentUser.contract = body.RN; 
          setNewScopeContract(body.file);
          setNewScopeContract(body.file);
          setNotificationContract( body.success ? 'El archivo se subió correctamente': body.message);
        } else  {
          rentUser.paycheck = body.RN;
          setNewScope(body.file);
          setNotification( body.success ? 'El archivo se subió correctamente': body.message);
        }
      });
    });
  };

  const saveFile = e => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  return (
    <form className="inscripcion" onSubmit={handleSubmitThanks}>
    <div className='input-box'>
    <label className='label-white'>{clientData.firstName}</label>
      <input name="userName" className="rent-user-input" value={rentUser.userName} type="onlyletters" onChange={e => handleChange(e, 'userName', 'onlyletters', true, 75, 3)} placeholder={placeholders.firstName} required={true}/>
      {errors['userName'] && <p className='error-white'>{errors['userName']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>{clientData.lastName}</label>
      <input name="userLastName" value={rentUser.userLastName} className='rent-user-input' type="onlyletters" onChange={e => handleChange(e, 'userLastName', 'onlyletters', true, 75, 3)} placeholder={placeholders.lastName} required={true}/> 
      {errors['userLastName'] && <p className='error-white'>{errors['userLastName']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>Email</label>
      <input name="email" className="rent-user-input" value={rentUser.email} type="email" onChange={e => handleChange(e, 'email', 'email', true, 75, 3)} placeholder={placeholders.email} required={true}/>
      {errors['email'] && <p className='error-white'>{errors['email']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>{clientData.phone}</label>
      <input name="phone" className="rent-user-input" value={rentUser.phone} type="number" onChange={e => handleChange(e, 'phone', 'number', true, 15, 7)} placeholder={placeholders.phone} required={true}/>
      {errors['phone'] && <p className='error-white'>{errors['phone']}</p>}
    </div>
    <div className='input-box'>
    <label className='label-white'>CUIT</label>
      <input name="cuit" className="rent-user-input" value={rentUser.cuit} type="number" onChange={e => handleChange(e, 'cuit', 'number', true, 15, 7)} placeholder={placeholders.cuit} required={true}/>
      {errors['cuit'] && <p className='error-white'>{errors['cuit']}</p>}
    </div>
    
    <div className='input-box'>
    <label className='label-white'>{clientData.attach1}</label>
      <input name="contract" className="rent-file-input" type="file" onChange={saveFile} />
      {errors['contract'] && <p className='error-white'>{errors['contract']}</p>}
      <button className={isMobile?'upload-button-mobile': "file-button"}type="button" onClick={() => uploadFile('contract')}>Subir</button>  
      <p className='rent-notification'>{newScopeContract ? `Nombre: ${newScopeContract} ` : null} {notificationContract}</p>
    </div>
    <br/>
    <div className='input-box'>
    <p className='label-white-long'>{clientData.attach2}</p>
      <input name="paycheck" className="rent-file-input"  type="file" onChange={saveFile} />
      {errors['paycheck'] && <p className='error-white'>{errors['paycheck']}</p>}
      <button className={isMobile?'upload-button-mobile': "file-button"}type="button" onClick={() => uploadFile('paycheck')}>Subir</button> 
      <p className='rent-notification'>{newScope ? `Nombre: ${newScope} ` : null}{notification}</p>
    </div>
    <br/>
    <div className='rent-result-box'>
    <button type="button" className="rent-button-secondary" onClick={handleCancel}>{clientData.cancel}</button>
    <button type="submit" className={isMobile ? "contact-dark-button":"rent-button"}>{clientData.send}</button>
    </div>
    {rentResponse?.error ? <p className='network-error'>{errorNetworkMessage}</p> : null}
    </form>
    )
}

export default CotizadorAlquilerCliente
