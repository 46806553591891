import React from 'react'
import './fourlogos.css'

const FourLogos = () => {
  return (
    <div className='four-logos-start'> 
          
    <div className='four-logos1'>
    <a href='https://icisa.org/' target="_blank" rel="noreferrer"><img src="img/LogoCertificaciones1.jpg" alt="ICISA"/></a>
    </div>
    <div className='four-logos2'>
    <a href='https://www.bureauveritas.com.ar/' target="_blank" rel="noreferrer"><img src="img/LogoCertificaciones2.jpg" alt="Bureau Veritas"/></a>
    </div> 
    <div className='four-logos3'>
    <a href='http://www.apfpasa.ch/' target="_blank" rel="noreferrer"><img src="img/LogoCertificaciones3.jpg" alt="Pasa APF"/></a>
    </div>
    <div className='four-logos4'>
    <a href='https://www.evaluadora.com/ar/resultadoCalificaciones.php?texto=AFIANZADORA+LATINOAMERICANA+C%CDA+DE+SEGUROS+S.A.%2C+Compa%F1%EDas+de+Seguros&categoria=228&empresa=100' target="_blank" rel="noreferrer"><img src="img/LogoCertificaciones4.jpg" alt="Evaluadora Latinoamericana"/></a>
    </div>
  </div>
  )
}

export default FourLogos