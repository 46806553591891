import React from 'react'
import { useState, useEffect } from 'react'
import'./contacto.css'
import BigButton from '../utils/BigButton/BigButton'
import { Link } from 'react-router-dom'
import { Validation } from '../utils/validation'
import ContactForm from './ContactForm'
import { isMobileOnly, MobileOnlyView } from 'react-device-detect'
import SubmenuContactoMobile from './SubmenuContactoMobile'
import ContactoTitleAndText from './ContactoTitleAndText'
import ContactoAdress from './ContactoAdress'
import { CvForm } from '../TrabajaConNosotros/CvForm'
import contactTexts from '../utils/Texts/contactTexts.json'
import { useLangContext } from '../../Context/LangContext'
import errorTexts from '../utils/Texts/errorTexts.json'
import { useContact } from './hooks/use-contact.hook'
import { useCv } from './../TrabajaConNosotros/hooks/use-cv.hook'


const Contacto = () => {
  const {selectedLanguage} = useLangContext()
  const text = contactTexts[selectedLanguage];
  const errorMessages = errorTexts[selectedLanguage];

  let [contactInfo, setContactInfo]=useState({
        userName: "",
        userLastName: "",
        email: "",
        broker: null,
        content:"",
        isValid: false
  })
  let [candidateInfo, setCandidateInfo]=useState({
    userName: "",
    userLastName: "",
    email: "",
    phone:"",
    dni:"",
    content:"",
    cv:""
  })
  const [sent, setSent]= useState(false)
  const [errors, setErrors]= useState({})
  const [change, setChange]= useState(false)
  const contacResponse = useContact(contactInfo)
  const cvResponse = useCv(candidateInfo)
 console.log(errors)
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
      
  const handleChange=(e, name, type, required = false, maxLength = false, minLength = false)=>{
    const infoInput=contactInfo
    const {changedInfoInput, value, err}= Validation(e, name, type, required, maxLength, minLength, infoInput, errors, errorMessages)     
      setContactInfo({...changedInfoInput, [e.target.name]:value});
      setErrors(err);
      } 

  const handleCandidate=(e, name, type, required = false, maxLength = false, minLength = false)=>{
        const infoInput=candidateInfo
        const {changedInfoInput, value, err}= Validation(e, name, type, required,maxLength, minLength, infoInput, errors, errorMessages)
        
            setCandidateInfo({...changedInfoInput, [e.target.name]:value});
            setErrors(err);
      } 
const handleSubmit=(e)=>{
  e.preventDefault();
  for (const error in errors) {
    if (errors[error]) {
       return;
     } else {
      change ? setCandidateInfo({...candidateInfo, isValid: true}) :
      contactInfo.broker = contactInfo.broker === 'true' || contactInfo.broker === 1 ? 1 : 0;
      setContactInfo({...contactInfo, isValid: true})
     }
   }
}

const handleClick=(e)=>{
  e.preventDefault();
  setChange(!change)
}

useEffect(() => {
  if(contacResponse.success) {
    setSent(true)
  }
}, [contacResponse])

useEffect(() => {
  if(cvResponse.success) {
    setSent(true)
  }
}, [cvResponse])

  return (
    <>
    
    <div className= {isMobileOnly ? "display-none" : 'contact-backgound'} >
    <div className='contact-grid'>
     <ContactoTitleAndText title={text.title} text={text.text}/>
      <ContactoAdress />
        <div className='work-button-in-contact'>
            <Link to="/trabaja-con-nosotros">
        <BigButton name={text.workButton} className="small-button" />
        </Link>
        </div>
        <ContactForm sent={sent} contactInfo={contactInfo} handleSubmit={handleSubmit} errors={errors} handleChange={handleChange}
        thanks1={text.thanks1} thanks2={text.thanks2} confirmation={text.confirmation} formTitle={text.formTitle} 
        send={text.sendButton} formTexts={text.formTexts} placeholders={text.placeholders} contactResponse={contacResponse} errorNetworkMessage={text.errorNetworkMessage}/>
      </div>
    </div>
    <div className="push"></div>
  
    <MobileOnlyView>
      <div className='first-box-mobile'>
        <SubmenuContactoMobile handleClick={handleClick} change={change} contactCategory={text.contactCategory} workButton={text.workButton} />
        <div className='img-box'>
        <img src="/img/MobileImages/ContactoMobile.jpg" alt="" />
        </div>
      </div>
      <div className='bordeax-background'>
        {change === false & sent === false? (
          <>
          <ContactoTitleAndText title={text.title} text={text.text}/>
          <ContactoAdress />
          <ContactForm sent={sent} contactInfo={contactInfo} handleSubmit={handleSubmit} errors={errors} handleChange={handleChange}
        thanks1={text.thanks1} thanks2={text.thanks2} confirmation={text.confirmation} formTitle={text.formTitle} 
        send={text.sendButton} formTexts={text.formTexts} placeholders={text.placeholders} contactResponse={contacResponse} errorNetworkMessage={text.errorNetworkMessage}/>
          </>) 
          :( 
          <>
          {sent===true ? <div>
                <h2 className='contact-thanks'>{text.thanks1} {candidateInfo.userName} {text.thanks2} </h2>
                <p className='contact-thanks-text'>{text.confirmation}</p>
                <div><Link to="/"><BigButton name= "HOME" className="contact-dark-button"/></Link></div></div>
                :<h2 className='contact-subtitle'>{text.cvTitle}</h2>}
          <CvForm sent={sent} handleSubmit={handleSubmit} handleChange={handleCandidate} errors={errors} candidateInfo={candidateInfo} classDark={'white-area'} className={'white-input'} formTexts={text.formTexts} sendButton={text.sendButton} placeholders={text.placeholders} cvResponse={cvResponse} uploadButton={text.uploadButton} errorNetworkMessage={text.errorNetworkMessage} />
          </>
          )
        }
        </div>

    </MobileOnlyView>
    </>
  )
}

export default Contacto