import React from "react";
import { useEffect } from "react";
import prensaData from "../utils/Texts/prensaData";
import "./prensa.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import PressCard from "./PressCard";

const Prensa = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const lastNote = prensaData[0];
  const [, ...rest] = prensaData;

  return (
    <div className="press-background">
      <div className="product-content">
        <div className="press-box">
          <div className="main-note-img">
            <img src={lastNote.img} alt={lastNote.alt} />
          </div>
          <div className="press-note-box">
            <div className="head-note">
              <h5>
                {lastNote.media}
              </h5>
              <h5 className="head-date">
                {lastNote.publicationDate}
              </h5>
            </div>
            <div className="main-note-content">
              <h2>
                {lastNote.title}
              </h2>
              <div className="box">
                <div className="box-text">
                  {lastNote.text.substring(0, 740)} ...
                </div>
              </div>
              <div>
                <Link to={`/prensa-detail/${lastNote.id}`} className="more">
                  <div className="see">Ver mas</div>
                  <div className="see-icon">
                    <Icon icon="ic:baseline-plus" className="icon-on-grey" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="menu-note">
              <h5>ULTIMAS NOTICIAS</h5>
            </div>
            <div>
              <Link to="/" className="press-menu-link">
                <h5>HOME</h5>
              </Link>
            </div>
          </div>
          <div className="second-grid">
            {rest.map((item, key) =>
              <PressCard key={item.id} prensaData={item} id={item.id} />
            )}
          </div>
        </div>
      </div>
      <div className="push" />
    </div>
  );
};

export default Prensa;
